import React from "react";
import Layout from "../../components/Layout";

export default function educacionEspecial() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/11.jpg)`}}
                    >
                        <h1>Educación Especial</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            GDC Difusión Científica, empresa mexicana con 34 años en el mercado de la información y
                            tecnología educativa en América Latina, pone a su consideración un atractivo portafolio de
                            soluciones orientadas a satisfacer las necesidades del <strong>Programa de Fortalecimiento
                            de los Servicios de Educación Especial (PFSEE)</strong>
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}